// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Notifications from "../../blocks/Notifications/src/Notifications";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import FeedbackCollection from "../../blocks/FeedbackCollection/src/FeedbackCollection";
import Payments from "../../blocks/Payments/src/Payments";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import AnimationsAndTransition3 from "../../blocks/AnimationsAndTransition3/src/AnimationsAndTransition3";
import UserGroups from "../../blocks/UserGroups/src/UserGroups";
import Analytics3 from "../../blocks/Analytics3/src/Analytics3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import CfInventoryManagement7 from "../../blocks/CfInventoryManagement7/src/CfInventoryManagement7";
import Sms2 from "../../blocks/Sms2/src/Sms2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Sorting from "../../blocks/sorting/src/Sorting";
import Maps from "../../blocks/Maps/src/Maps";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Location4 from "../../blocks/Location4/src/Location4";
import ProposalGeneration2 from "../../blocks/ProposalGeneration2/src/ProposalGeneration2";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ApiIntegrationForInventoryManagement from "../../blocks/ApiIntegrationForInventoryManagement/src/ApiIntegrationForInventoryManagement";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Videos4 from "../../blocks/Videos4/src/Videos4";
import SplitInventory2 from "../../blocks/SplitInventory2/src/SplitInventory2";
import Subscriptions from "../../blocks/Subscriptions/src/Subscriptions";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import AcceptPrepayments2 from "../../blocks/AcceptPrepayments2/src/AcceptPrepayments2";
import PhoneVerification from "../../blocks/PhoneVerification/src/PhoneVerification";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Reviews from "../../blocks/Reviews/src/Reviews";
import DiscountsOffers from "../../blocks/DiscountsOffers/src/DiscountsOffers";
import LandingPage2 from "../../blocks/LandingPage2/src/LandingPage2";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import CustomForm2 from "../../blocks/CustomForm2/src/CustomForm2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import PdfConvert from "../../blocks/PdfConvert/src/PdfConvert";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CarouselDisplay from "../../blocks/CarouselDisplay/src/CarouselDisplay";
import AutomaticRenewals2 from "../../blocks/AutomaticRenewals2/src/AutomaticRenewals2";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import PromoCodes from "../../blocks/PromoCodes/src/PromoCodes";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import ItemGrouper from "../../blocks/ItemGrouper/src/ItemGrouper";
import Chatbot6 from "../../blocks/Chatbot6/src/Chatbot6";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import Search from "../../blocks/Search/src/Search";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import SalesReporting from "../../blocks/SalesReporting/src/SalesReporting";



const routeMap = {
Notifications:{
 component:Notifications,
path:"/Notifications"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
FeedbackCollection:{
 component:FeedbackCollection,
path:"/FeedbackCollection"},
Payments:{
 component:Payments,
path:"/Payments"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
AnimationsAndTransition3:{
 component:AnimationsAndTransition3,
path:"/AnimationsAndTransition3"},
UserGroups:{
 component:UserGroups,
path:"/UserGroups"},
Analytics3:{
 component:Analytics3,
path:"/Analytics3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CfInventoryManagement7:{
 component:CfInventoryManagement7,
path:"/CfInventoryManagement7"},
Sms2:{
 component:Sms2,
path:"/Sms2"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Maps:{
 component:Maps,
path:"/Maps"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
SubscriptionBilling:{
 component:SubscriptionBilling,
path:"/SubscriptionBilling"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Location4:{
 component:Location4,
path:"/Location4"},
ProposalGeneration2:{
 component:ProposalGeneration2,
path:"/ProposalGeneration2"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ApiIntegrationForInventoryManagement:{
 component:ApiIntegrationForInventoryManagement,
path:"/ApiIntegrationForInventoryManagement"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Videos4:{
 component:Videos4,
path:"/Videos4"},
SplitInventory2:{
 component:SplitInventory2,
path:"/SplitInventory2"},
Subscriptions:{
 component:Subscriptions,
path:"/Subscriptions"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
AcceptPrepayments2:{
 component:AcceptPrepayments2,
path:"/AcceptPrepayments2"},
PhoneVerification:{
 component:PhoneVerification,
path:"/PhoneVerification"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
core:{
 component:core,
path:"/core"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
DiscountsOffers:{
 component:DiscountsOffers,
path:"/DiscountsOffers"},
LandingPage2:{
 component:LandingPage2,
path:"/LandingPage2"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
CustomForm2:{
 component:CustomForm2,
path:"/CustomForm2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
PdfConvert:{
 component:PdfConvert,
path:"/PdfConvert"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
AutomaticRenewals2:{
 component:AutomaticRenewals2,
path:"/AutomaticRenewals2"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
PromoCodes:{
 component:PromoCodes,
path:"/PromoCodes"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
ItemGrouper:{
 component:ItemGrouper,
path:"/ItemGrouper"},
Chatbot6:{
 component:Chatbot6,
path:"/Chatbot6"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
Search:{
 component:Search,
path:"/Search"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
SalesReporting:{
 component:SalesReporting,
path:"/SalesReporting"},

  Home: {
component:PhoneNumberInput,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
